<template>
  <div class="meeting_agree_list">
    <div class="meeting_agree_list_tabs">
      <div
        class="meeting_agree_list_tab"
        :class="item.value === tabActive ? 'active' : ''"
        v-for="item in tabOptions"
        :key="item.label"
        @click="handleTab(item)"
      >{{ item.label }}</div>
    </div>

    <div class="meeting_agree_list_empty" v-if="!list.length">
      <div>
        <img class="meeting_agree_list_empty__inner" src="@/img/icon-empty-meeting.png" alt="">
        <div class="meeting_agree_list_empty_label">当前暂无内容</div>
      </div>
    </div>
    <div class="meeting_agree_list_main" v-else>
      <div class="meeting_agree_list_item" v-for="item in list" :key="item.id" @click="clickHandle(item)">
        <div class="label">{{ item.moldName }}</div>
        <div class="btn" v-if="!item.laborAgreementSignUrl">去签署</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MeetingAgreeList',
    data: () => ({
      tabOptions: [
        { label: '未签署', value: 0 },
        { label: '已签署', value: 1 },
      ],
      tabActive: 0,
      list: []
    }),
    computed: {
      user () {
        return this.$store.getters['user/getLoginInfo']
      }
    },
    created () {
      if (this.$route.query.tabActive) {
        this.tabActive = this.$route.query.tabActive == 1 ? 1 : 0
      }
      this.load()
    },
    methods: {
      load () {
        if (!this.user.userId) {
          this.$router.replace('/meeting/agree/login')
          return
        }
        this.$api.meeting.getDoctorAgreementStatus({
          agreementStatus: this.tabActive,
          doctorId: this.user.userId
        }).then(res => {
          if (!res.code) {
            console.log(res.data)
            this.list = res.data || []
          }
        })
      },
      handleTab (item) {
        this.tabActive = item.value
        this.list = []
        this.load()
      },
      clickHandle (item) {
        if (item.laborAgreementSignUrl) {
          window.sessionStorage.setItem('laborAgreementUrl', item.laborAgreementUrl)
          // location.href= item.laborAgreementUrl
          this.$router.push('/meeting/agree/preview')
          return
        }
        sessionStorage.setItem('meetingAgreeContent', JSON.stringify(item))
        if (item.agreementQuestionContent) {
          this.$router.push('/meeting/agree/question')
          return
        }
        if (item.agreementContent) {
          this.$router.push('/meeting/agree/content')
          return
        }
      }
    }
  }
</script>

<style lang="less">
  .meeting_agree_list {
    min-height: 100vh;
    background-color: rgba(245, 246, 247, 1);
    box-sizing: border-box;
    padding: 0 16px;
    .meeting_agree_list_tabs {
      padding: 14px 0;
      display: flex;
      align-items: center;
      .meeting_agree_list_tab {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        color: #646A73;
        margin-right: 20px;
        position: relative;
        &.active {
          font-weight: 500;
          color: #1F2329;
          &:before {
            content: '';
            display: inline-block;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #1F2329;
            position: absolute;
            bottom: -2px;
            left: calc(50% - 1px);
          }
        }
      }
    }
    .meeting_agree_list_empty {
      margin-bottom: 10px;
      height: 270px;
      background-color: #FFF;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      .meeting_agree_list_empty__inner {
        width: 179px;
        height: 179px;
        vertical-align: middle;
      }
      .meeting_agree_list_empty_label {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        color: #646A73;
      }
    }
    .meeting_agree_list_main {
      .meeting_agree_list_item {
        background-color: #FFF;
        border-radius: 6px;
        padding: 17px 14px;
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          flex: auto;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0px;
          color: #1F2329;
        }
        .btn {
          margin-left: 10px;
          flex: 0 0 60px;
          width: 60px;
          height: 27px;
          border-radius: 3px;
          line-height: 27px;
          background-color: #F4CD13;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          color: #1F2329;
        }
      }
    }
  }
</style>